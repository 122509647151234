<template>
    <div>
<!--        <div v-if="isEmptyPortfolio()">{{$t('empty_portfolio')}}</div>-->
        <div v-if="isEmptyPortfolio()" class="empty_wrap">
          <div class="img_box"></div>
          <div class="text_box">{{$t('empty_portfolio')}}</div>
        </div>
        <div v-else class="editor ql-editor mb138" v-html="memberData.mb_portfolio"></div>
    </div>

</template>

<script>

import util from "@/mixins/util";

export default {
    name: "MemberDetailPortfolioLayout",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        isEmptyPortfolio() {
            return util.isEmpty(this.memberData.mb_portfolio)
        },
    },
    watch: {
    },
}
</script>

<style scoped>

</style>
